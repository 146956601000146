'use client';
import {
  CloseIcon,
  MuteVolumeIcon,
  PauseIcon,
  PlayIcon,
  UnMuteVolumeIcon,
} from '@/app/components/icons';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import { LongAudioWave } from '../../animations/audio-wave/LongAudioWave';
import { useMiniPlayerAudio } from '../miniplayer-audio/useMiniPlayerAudio';
import { cn } from '@/lib/utils';
import { durationToSeconds, formatDuration } from '@/utils/time/formatDuration';
import { PlayerFullScreenIcon } from './PlayerFullscreenIcon';

const WRAPPER_ID = 'mini-player';
const VIDEO_WRAPPER_ID = 'mini-video-player';
const MINI_VIDEO_PLAYER_CLASS = 'mini-video-player';
const MINI_PLAYER_ACTIVE_CLASS = 'active';

export const MiniVideoPlayer = () => {
  const playerRef = useRef<Player | null>(null);
  const [htmlVideoElement, setHtmlVideElement] = useState<HTMLVideoElement>();
  const [isLive, setIsLive] = useState(false);
  const [, setPlayerChanged] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [isLiveRadio, setIsLiveRadio] = useState(false);
  const [options, setOptions] = useState<typeof videojs.options>();
  const [title, setTitle] = useState<string>();
  const [topic, setTopic] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [background, setBackground] = useState<string>();
  const [htmlAudioElement, setHtmlAudioElement] = useState<HTMLAudioElement>();
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [isAudioEnded, setIsAudioEnded] = useState(false);

  const [isMuted, setIsMuted] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  const { setAudioData, onToggleMute, onTogglePlay, onPause, onClose } =
    useMiniPlayerAudio({
      htmlAudioElement,
      setTitle,
      setTopic,
      setDescription,
      setIsAudio,
      setIsLiveRadio,
      setIsLive,
      setIsPaused,
      setIsMuted,
    });

  const handlePlayClick = useCallback(() => {
    if (htmlAudioElement) {
      onTogglePlay();
      return;
    }
    setIsPaused(paused => {
      if (paused)
        playerRef.current?.play()?.catch(() => {
          // on video.js play error due to element unmount or pause before play
        });
      else playerRef.current?.pause();
      return !paused;
    });
  }, [playerRef, htmlAudioElement, onTogglePlay]);

  const handleMuteClick = useCallback(() => {
    if (htmlAudioElement) {
      onToggleMute();
      return;
    }
    setIsMuted(muted => !muted);
    playerRef.current?.muted(!playerRef.current?.muted());
  }, [playerRef, htmlAudioElement, onToggleMute]);

  const setTargetData = useCallback(
    (
      target: HTMLVideoElement,
      parentContainer: HTMLDivElement,
      targetOptions: typeof videojs.options,
    ) => {
      setHtmlVideElement(target);
      setIsMuted(target.muted);
      setIsPaused(target.paused);
      setTitle(parentContainer.dataset.title);
      setTopic(parentContainer.dataset.topic);
      setDescription(parentContainer.dataset.description);
      setIsLive(parentContainer.dataset.isLive === 'true');
      setIsAudio(parentContainer.dataset.isAudio === 'true');
      setIsLiveRadio(parentContainer.dataset.isLiveRadio === 'true');
      setOptions(targetOptions);
      setBackground(parentContainer.dataset.background);
    },
    [],
  );
  const playHandler = useCallback(
    (event: Event) => {
      const target = event.target as HTMLVideoElement;
      const parentContainer = target.closest(
        '.show-mini-player',
      ) as HTMLDivElement;

      if (
        target
          ?.closest('video-js')
          ?.classList.contains(MINI_VIDEO_PLAYER_CLASS) ||
        !parentContainer
      )
        return;

      const videoWrapper = (
        typeof document !== 'undefined' ? document : ({} as any)
      ).getElementById(VIDEO_WRAPPER_ID);
      let isMini = videoWrapper?.querySelector('video');

      const targetOptions = {
        ...(JSON.parse(
          parentContainer.dataset.options ?? '{}',
        ) as typeof videojs.options),
        controls: false,
        fluid: true,
        width: undefined,
        height: undefined,
        muted: target.muted,
        paused: target.paused,
      };
      const sameSources = targetOptions.sources?.some(
        (source: (typeof videojs.options)['sources']) =>
          source.src === options?.sources?.[0]?.src,
      );

      if (sameSources && isMini?.id !== target.id && isMini) {
        switchFromMiniPlayer({
          playerRef,
        });
        setHtmlVideElement(undefined);
        setIsLive(false);
        setIsLiveRadio(false);
        isMini = null;
      }
      if (isMini?.id !== target.id) {
        setIsPaused(true);
      }

      if (target.tagName === 'AUDIO' && parentContainer) {
        const player = playerRef.current;
        if (player && !player.isDisposed()) {
          player.pause();
        }
        if (htmlAudioElement && htmlAudioElement.id !== target.id) {
          htmlAudioElement.pause();
        }
        setTargetData(target, parentContainer, targetOptions);
        setHtmlAudioElement(target);
        setAudioData(target, parentContainer);
        setIsAudioEnded(false);
        return;
      }

      if ((isLive || isLiveRadio) && isMini) return;

      if (target.tagName === 'VIDEO' && parentContainer) {
        setTargetData(target, parentContainer, targetOptions);
        if (htmlAudioElement) {
          htmlAudioElement.pause();
          setHtmlAudioElement(undefined);
        }
      }
    },
    [
      isLive,
      isLiveRadio,
      options?.sources,
      setTargetData,
      setAudioData,
      htmlAudioElement,
    ],
  );

  // const unmuteHandler = useCallback(
  //   (event: Event) => {
  //     const target = event.target as HTMLVideoElement;
  //     const parentContainer = target.closest(
  //       '.show-mini-player',
  //     ) as HTMLDivElement;
  //     if (
  //       target
  //         ?.closest('video-js')
  //         ?.classList.contains(MINI_VIDEO_PLAYER_CLASS) ||
  //       !parentContainer
  //     )
  //       return;
  //
  //     const targetOptions = {
  //       ...(JSON.parse(
  //         parentContainer.dataset.options ?? '{}',
  //       ) as typeof videojs.options),
  //       controls: false,
  //       fluid: true,
  //       width: undefined,
  //       height: undefined,
  //       muted: target.muted,
  //       paused: target.paused,
  //     };
  //
  //     if (target.tagName === 'VIDEO' && parentContainer) {
  //       setTargetData(target, parentContainer, targetOptions);
  //     }
  //   },
  //   [setTargetData],
  // );
  const scrollHandler = useCallback(() => {
    const parentContainer = htmlVideoElement?.closest(
      '.show-mini-player',
    ) as HTMLDivElement;
    if (!parentContainer) return;
    const rect = htmlVideoElement?.getBoundingClientRect?.();
    if (
      rect &&
      htmlVideoElement &&
      typeof window !== 'undefined' &&
      window.innerWidth >= 768
    ) {
      if (rect.bottom - 100 < 0 || rect.top + 100 > window.innerHeight) {
        if (
          !htmlVideoElement.paused &&
          (!htmlVideoElement.muted ||
            parentContainer.dataset?.isLive === 'true' ||
            parentContainer.dataset?.isLiveRadio === 'true')
        ) {
          switchToMiniPlayer({
            htmlVideoElement: htmlVideoElement,
            options: {
              ...options,
              muted: htmlVideoElement.muted,
              paused: htmlVideoElement.paused,
              autoplay: false,
            },
            playerRef,
            htmlAudioElement,
          });
          if (htmlVideoElement.muted !== isMuted) {
            setIsMuted(htmlVideoElement.muted);
          }
          if (htmlVideoElement.paused !== isPaused) {
            setIsPaused(htmlVideoElement.paused);
          }
          setPlayerChanged(true);
        }
      } else {
        switchFromMiniPlayer({
          htmlVideoElement,
          playerRef,
        });
        setPlayerChanged(false);
      }
    }
  }, [htmlVideoElement, htmlAudioElement, options, isMuted, isPaused]);

  useEffect(() => {
    const doc = typeof document !== 'undefined' ? document : ({} as any);
    doc.addEventListener('play', playHandler, true);
    // doc.addEventListener('unmute', unmuteHandler, true);
    doc.addEventListener('scroll', scrollHandler);
    return () => {
      doc.removeEventListener('play', playHandler, true);
      // doc.removeEventListener('unmute', unmuteHandler, true);
      doc.removeEventListener('scroll', scrollHandler);
    };
  }, [playHandler, scrollHandler]);

  const pause = useCallback(() => setIsPaused(true), []);
  const volumeChange = useCallback(() => {
    if (playerRef.current?.muted()) {
      setIsMuted(true);
    } else {
      setIsMuted(false);
    }
  }, []);
  const play = useCallback(() => setIsPaused(false), []);

  useEffect(() => {
    const player = playerRef.current;
    player?.on('pause', pause);
    player?.on('play', play);
    player?.on('volumechange', volumeChange);
    return () => {
      player?.off('volumechange', pause);
      player?.off('volumechange');
      player?.off('play', play);
    };
  }, [pause, play, playerRef, volumeChange]);

  useEffect(() => {
    if (htmlAudioElement && window.innerWidth < 768 && isAudio) {
      const doc = typeof document !== 'undefined' ? document : ({} as any);
      const hamburgerMenu = doc.getElementById('hamburger-menu');
      if (hamburgerMenu) {
        hamburgerMenu.style.bottom = '120px';
      }
      const interval = setInterval(() => {
        setAudioCurrentTime(
          htmlAudioElement.duration - htmlAudioElement?.currentTime,
        );
      }, 100);

      htmlAudioElement.addEventListener('ended', function () {
        setIsAudioEnded(true);
      });

      return () => {
        clearInterval(interval);
        htmlAudioElement.removeEventListener('ended', function () {
          setIsAudioEnded(true);
        });
      };
    }
  }, [htmlAudioElement, isAudio]);

  const durationInSeconds =
    durationToSeconds(htmlAudioElement?.duration?.toString()) || 0;

  const percentage =
    audioCurrentTime > 0
      ? ((durationInSeconds - audioCurrentTime) / durationInSeconds) * 100 || 0
      : 0;

  const onMobileAudioClose = () => {
    onPause();
    setHtmlAudioElement(undefined);
    const doc = typeof document !== 'undefined' ? document : ({} as any);
    const hamburgerMenu = doc.getElementById('hamburger-menu');
    if (hamburgerMenu) {
      hamburgerMenu.style.bottom = '';
    }
  };

  return (
    <>
      <div
        id={WRAPPER_ID}
        className={
          'hidden w-[428px] pb-3.5 bg-grey-3 rounded-tl-custom overflow-hidden rounded-tr-custom flex-col justify-start items-start gap-4 fixed right-0 bottom-0 z-10'
        }
      >
        <div
          className={cn(
            'w-full relative flex flex-col justify-start items-start',
            htmlAudioElement &&
              'min-h-60 after:content-[""] after:z-1 after:w-full after:h-full after:absolute after:bottom-0 after:bg-gradient-to-b after:-from-[0%] after:to-[100%] after:from-[#00000000] after:to-[#000000E5] after:rounded-custom',
          )}
        >
          <div id={VIDEO_WRAPPER_ID} className={'w-full min-h-40'}></div>
          <div className="w-full opacity-25 bg-black" />
          {background ? (
            <Image
              src={background}
              alt={title || ''}
              className="object-cover relative"
              fill
            />
          ) : null}
          {(isAudio || isLiveRadio) && (
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[2]">
              <LongAudioWave color="#FFFFFF" isPlaying={!isPaused} />
            </div>
          )}
          {!(isAudio || isLiveRadio) && (
            <PlayerFullScreenIcon player={playerRef} />
          )}
          {(isLive || isLiveRadio) && (
            <div
              className={clsx('w-full h-1', {
                'bg-live': isLive,
                'bg-categories-opinion': isLiveRadio,
              })}
            />
          )}
        </div>
        <div className="w-full h-12 py-px pl-4 pr-6 relative max-w-[428px]">
          <div className="h-12 py-px flex justify-start items-center gap-3">
            <div className="flex flex-col justify-start items-start gap-2">
              <div className="flex justify-start items-center gap-2">
                <div
                  className={
                    'text-grey-11 text-lg font-medium font-notoserif truncate w-72'
                  }
                >
                  {title}
                </div>
              </div>
              <div className="w-72 self-stretch text-grey-11 text-xs2 font-normal font-notosans truncate">
                {description}
              </div>
            </div>
          </div>
          <div className="flex justify-start items-center gap-4 absolute ltr:right-0 rtl:left-0 top-1/2 -translate-y-1/2 pe-4 ps-3 h-full bg-grey-3">
            {isMuted ? (
              <UnMuteVolumeIcon
                onClick={handleMuteClick}
                className="cursor-pointer"
              />
            ) : (
              <MuteVolumeIcon
                onClick={handleMuteClick}
                className="cursor-pointer"
              />
            )}
            {isPaused ? (
              <PlayIcon onClick={handlePlayClick} className="cursor-pointer" />
            ) : (
              <PauseIcon onClick={handlePlayClick} className="cursor-pointer" />
            )}
          </div>
        </div>
        <div
          className={'absolute right-3 top-3 cursor-pointer'}
          onClick={() => {
            if (htmlAudioElement) {
              onClose();
            }
            switchFromMiniPlayer({
              autoplayMainVideo: false,
              playerRef,
            });
          }}
        >
          <CloseIcon color={'white'} />
        </div>
      </div>

      {isAudio && htmlAudioElement && (
        //Mobile audio view
        <div className="fixed bottom-0 w-full bg-white md:hidden flex flex-col gap-2 p-2.5 z-10">
          <div className="flex flex-row gap-1.5">
            {background ? (
              <div className="relative md:hidden flex items-center justify-center bg-black rounded-custom w-full max-w-[62px] h-[62px]">
                <Image
                  src={background}
                  alt={title || ''}
                  className="object-cover relative rounded-custom aspect-square opacity-50"
                  width={62}
                  height={62}
                />
                {isPaused ? (
                  <PlayIcon
                    color="white"
                    onClick={handlePlayClick}
                    className="cursor-pointer absolute"
                  />
                ) : (
                  <PauseIcon
                    color="white"
                    onClick={handlePlayClick}
                    className="cursor-pointer absolute"
                  />
                )}
              </div>
            ) : null}

            <div className="flex flex-col gap-1.5 w-full">
              <div className="text-primary-3 font-sf text-xs uppercase font-extrabold">
                {topic}
              </div>
              <div className="text-grey-13 font-notoserif text-sm2 font-bold line-clamp-2">
                {title}
              </div>
            </div>

            <div className={'cursor-pointer'} onClick={onMobileAudioClose}>
              <CloseIcon color={'black'} />
            </div>
          </div>
          {htmlAudioElement && (
            <div className="flex flex-row items-center justify-center gap-1.5 font-sf text-xs text-black">
              {formatDuration(Math.round(audioCurrentTime)) || '00:00'}
              <div className="w-full bg-grey-6 h-1 relative">
                <div
                  className="bg-grey-10 h-1 absolute left-0 top-0 transition-[width] ease-linear"
                  style={{ width: `${isAudioEnded ? 100 : percentage}%` }}
                />
              </div>
              {formatDuration(htmlAudioElement.duration || '')}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const switchToMiniPlayer = ({
  htmlVideoElement,
  options,
  playerRef,
  htmlAudioElement,
}: {
  htmlVideoElement?: HTMLVideoElement;
  options: typeof videojs.options;
  playerRef: MutableRefObject<Player | null>;
  htmlAudioElement?: HTMLAudioElement;
}) => {
  const doc = typeof document !== 'undefined' ? document : ({} as any);
  const wrapper = doc.getElementById(WRAPPER_ID) as HTMLDivElement;
  if (
    wrapper.classList.contains(MINI_PLAYER_ACTIVE_CLASS) ||
    playerRef.current?.isFullscreen()
  ) {
    return;
  }
  wrapper?.classList.add(MINI_PLAYER_ACTIVE_CLASS, 'inline-flex');
  wrapper?.classList.remove('hidden');

  const videoWrapper = doc.getElementById(VIDEO_WRAPPER_ID);
  const isMini = videoWrapper?.querySelector('video-js') as HTMLVideoElement;
  if (isMini) return;
  if (!htmlVideoElement) return;

  const miniVideo = doc.createElement('video-js');
  miniVideo.classList.add(MINI_VIDEO_PLAYER_CLASS, 'w-full');

  doc.getElementById(VIDEO_WRAPPER_ID)?.appendChild(miniVideo);
  const player = (playerRef.current = videojs(miniVideo, options, () => {
    player.currentTime(htmlVideoElement.currentTime);
    const playing = !htmlVideoElement.paused;
    if (htmlAudioElement) {
      return true;
    }
    htmlVideoElement.pause();
    if (playing) {
      player.play()?.catch(() => {
        // on video.js play error due to element unmount or pause before play
      });
      setTimeout(() => {
        player.play()?.catch(() => {
          // on video.js play error due to element unmount or pause before play
        });
      }, 100);
    }
  }));
  return true;
};

const switchFromMiniPlayer = ({
  htmlVideoElement,
  autoplayMainVideo = true,
  playerRef,
}: {
  htmlVideoElement?: HTMLVideoElement;
  autoplayMainVideo?: boolean;
  playerRef: MutableRefObject<Player | null>;
}) => {
  const doc = typeof document !== 'undefined' ? document : ({} as any);
  const videoWrapper = doc.getElementById(VIDEO_WRAPPER_ID);
  const miniVideo = videoWrapper?.querySelector('video-js');
  if (!miniVideo || playerRef.current?.isFullscreen()) return;
  videoWrapper?.removeChild(miniVideo);
  const wrapper = doc.getElementById(WRAPPER_ID) as HTMLDivElement;

  wrapper?.classList.remove(MINI_PLAYER_ACTIVE_CLASS, 'inline-flex');
  wrapper?.classList.add('hidden');

  const currentTime = playerRef.current?.currentTime() || 0;
  if (!htmlVideoElement) return;
  htmlVideoElement.muted = !!playerRef.current?.muted();
  htmlVideoElement.currentTime = currentTime;
  if (!playerRef.current?.paused() && autoplayMainVideo) {
    htmlVideoElement
      ?.play()
      ?.then()
      ?.catch(() => {
        // on video.js play error due to element unmount or pause before play
      });
  }
  playerRef.current = null;
};
