import { FullScreenIcon } from '@/app/components/icons';
import { MutableRefObject } from 'react';
import Player from 'video.js/dist/types/player';

type PlayerFullScreenIconProps = {
  player?: MutableRefObject<Player | null>;
};

export const PlayerFullScreenIcon = ({ player }: PlayerFullScreenIconProps) => {
  const onClick = () => {
    if (player) {
      player.current?.requestFullscreen().then(() => {
        const doc = typeof document !== 'undefined' ? document : ({} as any);
        const handleFullscreenChange = () => {
          const isFullscreen = doc.fullscreenElement !== null;
          player.current?.controls(isFullscreen);
        };
        doc.addEventListener('fullscreenchange', handleFullscreenChange);
        player.current?.on('dispose', () => {
          doc.removeEventListener('fullscreenchange', handleFullscreenChange);
        });
      });
    }
  };
  return (
    <FullScreenIcon
      className={'absolute text-white bottom-3 right-2 cursor-pointer'}
      onClick={onClick}
    />
  );
};
