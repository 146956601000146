'use client';

import React, {
  ComponentProps,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Content, SelectedMedia } from '@/lib/model';
import { CloseIcon } from '@/app/components/icons';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './VideoModal.css';
import Player from 'video.js/dist/types/player';
import videojs from 'video.js';
import { getContentMetadata } from '@/common/utils';
import type { Swiper as SwiperClass } from 'swiper/types';
import { getVidoeSrc } from '@/common/getVideoSrc';
import { LinkWithChannelLink } from '../link/LinkWithChannel';
import { PlatformMediaBox } from '../cards/common';
import { useTranslations } from 'next-intl';

type Props = {
  videoIndex?: number;
  onClose?: () => void;
  initialSlide?: number;
  contents: Content[];
};

export function VideoModal({
  videoIndex,
  initialSlide,
  onClose,
  contents,
}: Props) {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  useEffect(() => {
    if (videoIndex !== undefined) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [videoIndex]);

  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  const onVideoPlayEvent = (slider: SwiperClass) => {
    const activeIndex = slider.activeIndex;
    const video = contents?.[activeIndex];
    window.dataLayer.push({
      event: 'virtual_page_view',
      page_title: video.title,
      page_path: `/video/${video.slug}`,
    });
  };

  const handleSlideChange = (swiper: SwiperClass) => {
    onVideoPlayEvent(swiper);
    videoRefs.current.forEach(video => {
      if (video?.pause) {
        video.pause?.();
      }
    });
    const activeIndex = swiper.activeIndex;

    const activeVideo = videoRefs.current[activeIndex];
    if (activeVideo?.play) {
      activeVideo.play()?.catch(() => {
        // on video.js play error due to element unmount or pause before play
      });
    }
  };

  useEffect(() => {
    if (swiper && initialSlide) {
      setTimeout(() => {
        swiper?.slideTo(initialSlide);
      }, 1000);
    }
  }, [swiper, initialSlide]);

  const init = useRef(false);
  if (videoIndex === undefined) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black flex justify-center items-center z-50">
      <Swiper
        navigation
        modules={[Navigation]}
        className={'w-full'}
        initialSlide={initialSlide}
        onSwiper={setSwiper}
        onSlideChange={handleSlideChange}
        updateOnWindowResize={true}
        onInit={swiper => {
          if (!init.current) {
            init.current = true;
            onVideoPlayEvent(swiper);
          }
        }}
        resizeObserver={true}
        slidesPerView={1}
        watchSlidesProgress={true}
        watchOverflow={true}
      >
        {contents &&
          contents?.map((item, index) => {
            const { mobileContentMetadata, desktopContentMetadata } =
              getContentMetadata(item);
            const contentMetadata =
              typeof window !== 'undefined' && window.outerWidth < 768
                ? mobileContentMetadata
                : desktopContentMetadata;
            const media =
              (contentMetadata.medias?.find(m => {
                return typeof m.media === 'string'
                  ? /mp4$|mkv|webm|ogv/gi.test(m.media)
                  : (m.media as SelectedMedia)?.media?.type === 'VIDEO';
              })?.media as SelectedMedia) ?? (item.media as SelectedMedia);
            return (
              <SwiperSlide key={item.id}>
                <div className="relative z-20">
                  <div className="bg-transparent relative cursor-pointer">
                    {media ? (
                      <Video
                        item={item}
                        media={media}
                        autoplay={index === initialSlide}
                        ref={ref => {
                          videoRefs.current[index] = ref;
                        }}
                      />
                    ) : (
                      <OtherMedia
                        item={item}
                        mobileContentMetadata={mobileContentMetadata}
                        desktopContentMetadata={desktopContentMetadata}
                      />
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>

      <div
        className="w-10 h-10 bg-white rounded-full absolute right-4 md:right-10 top-4 md:top-10 flex justify-center items-center cursor-pointer z-20"
        onClick={onClose}
      >
        <CloseIcon className="text-[#111111]" />
      </div>
    </div>
  );
}

const ViewDetails = ({ slug }: { slug: string }) => {
  const t = useTranslations();
  return (
    <div
      className="absolute right-4 bottom-4 transition-opacity duration-300 ease-linear z-10"
      id="detail-btn"
    >
      <LinkWithChannelLink
        href={`/video/${slug}`}
        className="font-notosans underline text-grey-1 text-sm font-semibold leading-6.5"
      >
        {t('view-details')}
      </LinkWithChannelLink>
    </div>
  );
};

const OtherMedia = ({
  mobileContentMetadata,
  desktopContentMetadata,
  item,
}: ComponentProps<typeof PlatformMediaBox> & { item: Content }) => {
  return (
    <div className="max-w-vw100 mt-auto mb-auto overflow-hidden h-screen relative w-full aspect-[0.61/1] rounded-custom">
      <PlatformMediaBox
        width={61}
        height={100}
        mobileContentMetadata={mobileContentMetadata}
        desktopContentMetadata={desktopContentMetadata}
        className="w-full h-full"
      />
      <ViewDetails slug={item?.slug as string} />
    </div>
  );
};

type VideoProps = {
  media?: SelectedMedia;
  item: Content;
} & typeof videojs.options;
const initialOptions = {
  loop: true,
  controls: true,
  fluid: false,
  muted: false,
  bigPlayButton: false,
  width: 333,
  playsinline: true,
  preload: 'none',
  controlBar: {
    volumePanel: false,
    muteToggle: true,
    pictureInPictureToggle: false,
    progressControl: false,
    fullscreenToggle: false,
    remainingTimeDisplay: false,
  },
} as typeof videojs.options;
const Video = forwardRef<HTMLVideoElement, VideoProps>(
  ({ item, media, ...props }: VideoProps, ref) => {
    const videoRef = React.useRef<HTMLDivElement | null>(null);
    const playerRef = React.useRef<Player | null>(null);
    const options = useMemo(() => {
      return {
        ...initialOptions,
        ...props,
        sources: [
          {
            src: getVidoeSrc(media, '720p'),
            type:
              media?.mimeType === 'application/x-www-form-urlencoded' ||
              media?.mimeType === 'application/octet-stream'
                ? 'video/mp4'
                : media?.mimeType,
          },
        ],
      };
    }, [props, media]);

    // Use useImperativeHandle to expose play and pause methods
    useImperativeHandle(
      ref,
      () =>
        ({
          play: () => {
            if (playerRef.current) {
              playerRef.current?.play?.();
            }
          },
          pause: () => {
            if (playerRef.current) {
              playerRef.current?.pause?.();
            }
          },
        }) as HTMLVideoElement,
    );

    useEffect(() => {
      if (!playerRef.current) {
        const videoElement = document.createElement('video-js');
        videoElement.classList.add('videojs-modal');
        videoRef?.current?.appendChild(videoElement);
        playerRef.current = videojs(videoElement, options, () => {});
      } else {
        const player = playerRef.current;
        player?.src(options.sources);
      }
    }, [options, videoRef]);

    useEffect(() => {
      const player = playerRef.current;
      player?.on('useractive', () => {
        const detailButton = document.getElementById('detail-btn');
        if (detailButton) {
          detailButton.style.opacity = '1';
        }
      });

      player?.on('userinactive', () => {
        const detailButton = document.getElementById('detail-btn');
        if (detailButton) {
          detailButton.style.opacity = '0';
        }
      });
      return () => {
        if (player && !player.isDisposed()) {
          player.dispose();
          playerRef.current = null;
        }

        player?.off('useractive');
        player?.off('userinactive');
      };
    }, [playerRef]);
    useEffect(() => {
      if (props.autoplay) {
        playerRef.current?.play()?.catch(() => {
          // on video.js play error due to element unmount or pause before play
        });
      } else {
        playerRef.current?.pause();
      }
    }, [props.autoplay]);

    return (
      <div
        className={'video-container lite-video-container max-wsc'}
        data-vjs-player={true}
      >
        <div
          className={
            'max-w-vw100 mt-auto mb-auto overflow-hidden h-screen relative w-full aspect-[0.61/1] rounded-custom'
          }
          ref={videoRef}
        />
        <ViewDetails slug={item.slug} />
      </div>
    );
  },
);
