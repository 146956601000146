import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

export function useMiniPlayerAudio({
  htmlAudioElement,
  setTitle,
  setTopic,
  setDescription,
  setIsAudio,
  setIsLiveRadio,
  setIsLive,
  setIsPaused,
  setIsMuted,
}: {
  htmlAudioElement?: HTMLAudioElement;
  setTitle: Dispatch<SetStateAction<string | undefined>>;
  setTopic: Dispatch<SetStateAction<string | undefined>>;
  setDescription: Dispatch<SetStateAction<string | undefined>>;
  setIsAudio: Dispatch<SetStateAction<boolean>>;
  setIsLiveRadio: Dispatch<SetStateAction<boolean>>;
  setIsLive: Dispatch<SetStateAction<boolean>>;
  setIsPaused: Dispatch<SetStateAction<boolean>>;
  setIsMuted: Dispatch<SetStateAction<boolean>>;
}) {
  const setAudioData = (
    target: HTMLAudioElement,
    parentContainer: HTMLDivElement,
  ) => {
    setTopic(parentContainer.dataset.topic);
    setTitle(parentContainer.dataset.title);
    setDescription(parentContainer.dataset.description);
    setIsAudio(parentContainer.dataset.isAudio === 'true');
    setIsLiveRadio(parentContainer.dataset.isLiveRadio === 'true');
    setIsLive(false);
  };

  const onClose = useCallback(() => {
    if (!htmlAudioElement) return;
    setIsPaused(true);
    htmlAudioElement.pause();
  }, [htmlAudioElement, setIsPaused]);

  const onPause = useCallback(() => {
    if (!htmlAudioElement) return;

    setIsPaused(true);

    htmlAudioElement.pause();
  }, [htmlAudioElement, setIsPaused]);
  const onTogglePlay = useCallback(() => {
    if (!htmlAudioElement) return;

    if (htmlAudioElement.paused) {
      setIsPaused(false);
      htmlAudioElement.play()?.catch(() => {
        // on video.js play error due to element unmount or pause before play
      });
      return;
    }
    setIsPaused(true);

    htmlAudioElement.pause();
  }, [htmlAudioElement, setIsPaused]);

  const onToggleMute = useCallback(() => {
    if (!htmlAudioElement) return;
    setIsMuted(p => !p);
    if (htmlAudioElement.muted) {
      htmlAudioElement.muted = false;
      return;
    }
    htmlAudioElement.muted = true;
  }, [htmlAudioElement, setIsMuted]);

  useEffect(() => {
    if (!htmlAudioElement) return;
    const playHandler = () => {
      setIsPaused(false);
    };
    const pauseHandler = () => {
      setIsPaused(true);
    };
    htmlAudioElement?.addEventListener('play', playHandler, true);
    htmlAudioElement?.addEventListener('pause', pauseHandler, true);
    return () => {
      htmlAudioElement?.removeEventListener('play', playHandler, true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      htmlAudioElement?.removeEventListener('pause', pauseHandler, true);
    };
  }, [htmlAudioElement, setIsPaused]);

  return {
    htmlAudioElement,
    setAudioData,
    onTogglePlay,
    onToggleMute,
    onClose,
    onPause,
  };
}
