'use client';
import { useCallback, useEffect } from 'react';
import videojs from 'video.js';

export const AutoPlayByScroll = () => {
  const scrollHandler = useCallback(() => {
    const videoContainers: NodeListOf<Element> =
      typeof document === 'undefined'
        ? ([{}] as unknown as NodeListOf<Element>)
        : document.querySelectorAll('.video-container.autoplay');
    videoContainers.forEach(videoContainer => {
      if (!videoContainer.classList.contains('touched')) {
        const rect = videoContainer.getBoundingClientRect();
        const videoJsElement = videoContainer.querySelector('video-js');
        const player = videoJsElement ? videojs(videoJsElement) : null;
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          player?.play()?.catch(() => {
            // on video.js play error due to element unmount or pause before play
          });
        } else {
          player?.pause();
        }
      }
    });
  }, []);
  const playHandler = useCallback((event: Event) => {
    const target = event.target as HTMLVideoElement;
    const parentContainer = target.closest(
      '.video-container.autoplay',
    ) as HTMLDivElement;
    if (parentContainer) {
      const rect = target.getBoundingClientRect();
      if (!(rect.top >= 0 && rect.bottom <= window.innerHeight)) {
        parentContainer.classList.add('touched');
      }
    }
  }, []);
  const pauseHandler = useCallback((event: Event) => {
    const target = event.target as HTMLVideoElement;
    const parentContainer = target.closest(
      '.video-container.autoplay',
    ) as HTMLDivElement;
    if (parentContainer) {
      const rect = target.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        parentContainer.classList.add('touched');
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    window.addEventListener('load', scrollHandler);
    window.addEventListener('play', playHandler, true);
    window.addEventListener('pause', pauseHandler, true);
    window.addEventListener('volumechange', pauseHandler, true);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('load', scrollHandler);
      window.removeEventListener('play', playHandler, true);
      window.removeEventListener('pause', pauseHandler, true);
      window.removeEventListener('volumechange', pauseHandler, true);
    };
  }, [pauseHandler, playHandler, scrollHandler]);
  return null;
};
